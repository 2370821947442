import appSoftwareDevelopment from '../assets/animations/workflow/app-software-development.json';
import graphicDesign from '../assets/animations/workflow/graphic-design.json';
import illustration from '../assets/animations/workflow/illustration.json';
import projectManagement from '../assets/animations/workflow/project-management.json';
import productDevelopment from '../assets/animations/workflow/product-development.json';
import webDesign from '../assets/animations/workflow/web-design.json';

const useAnimationSrc = (category) => {
    switch (category) {
        default:
        case 'app-software-development':
            return appSoftwareDevelopment;
        case 'graphic-design':
            return graphicDesign;
        case 'illustration':
            return illustration;
        case 'project-management':
            return projectManagement;
        case 'product-development':
            return productDevelopment;
        case 'webDesign':
            return webDesign;
    }
};

export default useAnimationSrc;
