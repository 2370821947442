import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';
import ServicesTabs from '../components/services-tabs/ServicesTabs';
import VisibilitySensor from '../components/VisibilitySensor';
import ShortContact from '../components/short-contact/ShortContact';
import Layout from '../components/layout';
import {graphql} from 'gatsby';

const Services = ({data}) => {
    const {formatMessage} = useIntl();

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'services.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'services.page-description'})} />
            </Helmet>
            <VisibilitySensor>
                <ServicesTabs edges={data.allContentfulArticle.edges} />
            </VisibilitySensor>
            <VisibilitySensor>
                <ShortContact />
            </VisibilitySensor>
        </Layout>
    )
};

export default Services;

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
          excerpt
          category {
            color
            slug
          }
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
