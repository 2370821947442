import React from 'react';
import {Col, Row, Typography, Button, Divider} from 'antd';
import useGlobalState from '../../hooks/useGlobalState';
import competenciesConfig from '../../configs/competencies-config';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './ServicesTabs.module.less';
import {Link} from 'gatsby';
import {withStyles, Tabs, Tab} from '@material-ui/core';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import PropTypes from 'prop-types';
import ProjectsTeaser from '../projects-teaser/ProjectsTeaser';
import WorkflowTabs from '../workflow-tabs/WorkflowTabs';

const TabPanel = ({category, value, index}) => {
    const {formatMessage, locale} = useIntl();
    const {Title} = Typography;

    return (
        <div className={value !== index ? 'd-none' : ''}>
            <Row className={styles.row}>
                <Col>
                    <img
                        src={category.image}
                        alt={formatMessage({id: category.heading})}
                        className={styles.image}
                    />
                </Col>
                <Col className={styles.tabContentWrapper}>
                    <Title level={3} className={`${styles.tabPaneTitle} tab-pane-title`}>
                        {formatMessage({id: category.heading})}
                    </Title>
                    <p className={styles.tabPaneContent}>{formatMessage(
                        {id: category.text},
                        {strong: chunks => <strong>{chunks}</strong>}
                    )}</p>
                    <Link to={`/${locale}/contact`} className={styles.buttonLink}>
                        <Button type="primary" className={'animated'}>
                            {formatMessage({id: 'services-tabs.button'})}
                        </Button>
                    </Link>
                </Col>
            </Row>
        </div>
    )
}

const StyledTab = withStyles(() => ({
    root: {
        textTransform: 'none',
        fontWeight: 500,
        fontSize: 17,
        padding: '30px 0',
        marginRight: 34,
        whiteSpace: 'nowrap',

        '@media (max-width: 599px)': {
            padding: '15px 0',
            marginRight: 0,

            '&:first-child': {
                marginLeft: 20,
            },
        },
    },
    wrapper: {
        flexFlow: 'row nowrap',

        '& svg': {
            color: '#00C2E9',
            width: 60,
            minWidth: 60,
            height: 60,
            marginRight: 16,
            marginBottom: 0,
        },
        '@media (max-width: 599px)': {
            maxWidth: 60,
            justifyContent: 'flex-start',
            marginRight: 15,
        }
    },
    textColorInherit: {
        opacity: 1,

        '&.Mui-selected': {
            color: '#4E0921',
        },
    },
}))(Tab);

const StyledTabs = withStyles(() => ({
    root: {
        borderBottom: '1px solid #E7E7E7',

        '@media (max-width: 768px)': {
            margin: '0 -20px',
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    scrollButtons: {
        '& .MuiSvgIcon-root': {
            fontSize: 36,
            color: '#161616',
        },
    },
}))(Tabs);

const ServicesTabs = ({edges}) => {
    const globalState = useGlobalState();
    const activeTab = globalState.activeCompetenciesTab;
    const {formatMessage} = useIntl();

    const categoryPosts = edges
        .filter((edge) => !(activeTab && edge.node.category.slug !== activeTab))
        .map((edge) => edge.node);

    const handleTabsChange = (event, newValue) => {
        globalState.setActiveCompetenciesTab(newValue);
    };

    return (activeTab &&
        <>
            <div className="container">
                <StyledTabs
                    value={activeTab}
                    className="icon-tabs"
                    onChange={handleTabsChange}
                    variant="scrollable"
                    TabScrollButtonProps={{
                        disableRipple: true,
                        icon: <ArrowDownIcon />,
                    }}
                >
                    {
                        competenciesConfig.map(category => {
                            return (
                                <StyledTab
                                    id={category.id}
                                    key={category.id}
                                    icon={category.id === activeTab ? category.activeIcon : category.icon}
                                    value={category.id}
                                    label={formatMessage({id: category.label})}
                                    disableRipple
                                />
                            );
                        })
                    }
                </StyledTabs>
                <div>
                    {competenciesConfig.map(category => {
                        return (
                            <TabPanel category={category} value={activeTab} index={category.id} key={category.id} />
                        )})}
                    <Divider className={styles.divider} />
                </div>
                <ProjectsTeaser postsList={categoryPosts} plain />
            </div>
            <WorkflowTabs category={activeTab} />
        </>
    );
};

ServicesTabs.propTypes = {
  edges: PropTypes.array.isRequired,
};

export default ServicesTabs;
